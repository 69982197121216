import React, { useState, useEffect } from "react";
import instance from "base_url";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import Alert from "components/Common/Alert";
import Accordion from "react-bootstrap/Accordion";

function FamilyInformation({ t }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [familyInfo, setFamilyInfo] = useState({});
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [isRedCrescent, setIsRedCrescent] = useState(null);
  const [reasonForStop, setReasonForStop] = useState("");
  const [errorFields, setErrorFields] = useState([]);
  const [totalFamilyIncome, setTotalFamilyIncome] = useState(0);

  const [expenses, setExpenses] = useState({
    waterBill: 0,
    gasBill: 0,
    electricityBill: 0,
    internetBill: 0,
    otherBill: 0,
  });
  const [incomes, setIncomes] = useState({
    salary: 0,
    redCrescent: "",
  });
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    description: "",
    type: "",
  });
  const [individualList, setIndividualList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  // دالة التحقق من الحقول المطلوبة
  const validateFields = () => {
    const requiredFields = [
      "rentAmount",
      "salary",
      // "redCrescent",
      "waterBill",
      "gasBill",
      "electricityBill",
      "internetBill",
      // "otherBill",
    ];
    const errors = requiredFields.filter(
      (field) => !expenses[field]?.amount && !incomes[field]?.amount
    );
    setErrorFields(errors);
    return errors.length === 0;
  };

  // Fetch family details
  const getFamilyDetails = async () => {
    try {
      const { data } = await instance.get(`/families/${id}/`);
      const salary = data.incomes?.find(
        (item) => item.title?.item_name === "Salary"
      );
      const redCrescent = data.incomes?.find(
        (item) => item.title?.item_name === "Red Crescent"
      );
      setIncomes({
        salary: salary,
        redCrescent: redCrescent,
      });

      if (redCrescent && redCrescent.amount == 0) {
        setIsRedCrescent("No");
      } else {
        setIsRedCrescent("Yes");
      }

      const waterBill = data.expenses?.find(
        (item) => item.title?.item_name === "Water Bill"
      );
      const gasBill = data.expenses?.find(
        (item) => item.title?.item_name === "Gas Bill"
      );
      const electricityBill = data.expenses?.find(
        (item) => item.title?.item_name === "Electricity Bill"
      );
      const internetBill = data.expenses?.find(
        (item) => item.title?.item_name === "Internet Bill"
      );
      const otherBill = data.expenses?.find(
        (item) => item.title?.item_name === "Other Bill"
      );
      const rent = data.expenses?.find(
        (item) => item.title?.item_name === "Rent Amount"
      );
      setExpenses({
        waterBill: waterBill,
        gasBill: gasBill,
        electricityBill: electricityBill,
        internetBill: internetBill,
        otherBill: otherBill,
        rentAmount: rent,
      });

      const totalExpenses =
        parseInt(waterBill?.amount || 0) +
        parseInt(gasBill?.amount || 0) +
        parseInt(electricityBill?.amount || 0) +
        parseInt(internetBill?.amount || 0) +
        parseInt(otherBill?.amount || 0);
      setTotalExpenses(totalExpenses);
    } catch (error) {
      console.error(t("Error fetching family data:"), error);
    }
  };

  // Fetch individual list
  const getIndividuals = async () => {
    try {
      const { data } = await instance.get(`/families/${id}/individuals/`);
      setIndividualList(data); // Update individual list
      const totalIncome = data.reduce((sum, individual) => {
        return individual.is_working
          ? sum + parseInt(individual.salary || 0)
          : sum;
      }, 0);

      setTotalFamilyIncome(totalIncome);
    } catch (error) {
      console.error(t("Error fetching individuals:"), error);
    }
  };

  useEffect(() => {
    getFamilyDetails();
    getIndividuals();
  }, [id]);

  // Save family information
  const saveFamilyInfo = async () => {
    if (!validateFields()) {
      setAlert({
        show: true,
        message: t("Error"),
        description: t("Please fill in all required fields."),
        type: "error",
      });
      return;
    }

    setIsSaving(true);
    const payload = [];

    for (const [key, value] of Object.entries(expenses)) {
      if (value) {
        payload.push({
          key: key,
          amount: value.amount || null,
          family: value.family || null,
          additional_info: value.additional_info || null,
          id: value.id || null,
          type: "expense",
        });
      }
    }

    for (const [key, value] of Object.entries(incomes)) {
      if (key === "redCrescent" && isRedCrescent === "No") {
        payload.push({
          key: key,
          amount: 0,
          family: id || null,
          additional_info: value.additional_info || null,
          id: value.id || null,
          type: "income",
        });
      } else {
        if (value) {
          payload.push({
            key: key,
            amount: value.amount || null,
            family: id || null,
            additional_info: value.additional_info || null,
            id: value.id || null,
            type: "income",
          });
        }
      }
    }

    try {
      const response = await instance.put(
        `/families/${id}/save_information/`,
        payload
      );
      setAlert({
        show: true,
        message: t("Success"),
        description: t("Family information has been saved successfully."),
        type: "success",
      });
      navigate(-1);
    } catch (error) {
      console.error(t("Error saving family information:"), error);
      setAlert({
        show: true,
        message: t("Error"),
        description: t("Error saving family information."),
        type: "error",
      });
    }

    setIsSaving(false);
  };

  const calculateTotalExpenses = (item, value) => {
    // update the value of the item in the total expenses
    let total = 0;
    for (const [key, value] of Object.entries(expenses)) {
      if (key == "rentAmount") {
        continue;
      }
      if (key != item) {
        total += parseInt(value?.amount || 0);
      }
    }
    total += parseInt(value);
    setTotalExpenses(total);
  };

  console.log(expenses);
  console.log(incomes);
  return (
    <React.Fragment>
      <div className="page-content">
        {alert.show && (
          <Alert
            message={alert.message}
            description={alert.description}
            type={alert.type}
            onClose={() =>
              setAlert({ show: false, message: "", description: "", type: "" })
            }
          />
        )}

        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center mb-3">
              <Col md={8}>
                <h6 className="page-title">{t("Edit Family Information")}</h6>
              </Col>
            </Row>

            <div className="form-page-container">
              {/* Rent Amount */}
              <Row className="mb-4">
                <Col md={2}>
                  <strong>{t("Rent Amount")}</strong>
                </Col>
                <Col md={4}>
                  <input
                    type="number"
                    className="form-control"
                    value={
                      expenses.rentAmount?.amount
                        ? parseInt(expenses.rentAmount.amount, 10)
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d]/g, "");
                      setExpenses({
                        ...expenses,
                        rentAmount: { ...expenses.rentAmount, amount: value },
                      });
                    }}
                    required
                    style={{
                      height: "40px",
                      border: errorFields.includes("rentAmount")
                        ? "1px solid red"
                        : "1px solid #ced4da",
                    }}
                  />
                </Col>
              </Row>

              {/* Family Income */}
              <Row className="mb-4">
                <Col md={2}>
                  <strong>{t("Family Income")}</strong>
                </Col>
                <Col md={4}>
                  <input
                    type="number"
                    className="form-control"
                    value={totalFamilyIncome || ""}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d]/g, "");
                      setTotalFamilyIncome(value);

                      setIncomes((prevIncomes) => ({
                        ...prevIncomes,
                        salary: { ...prevIncomes.salary, amount: value },
                      }));
                    }}
                    required
                    style={{
                      height: "40px",
                      border: errorFields.includes("rentAmount")
                        ? "1px solid red"
                        : "1px solid #ced4da",
                    }}
                  />
                </Col>
              </Row>

              {/* Red Crescent */}
              <Row className="mb-4">
                <Col md={2}>
                  <strong>{t("Red Crescent")}</strong>
                </Col>
                <Col md={4}>
                  <select
                    className="form-control form-select"
                    value={isRedCrescent}
                    onChange={(e) => setIsRedCrescent(e.target.value)}
                    required
                    style={{
                      height: "40px",
                      border: errorFields.includes("redCrescent")
                        ? "1px solid red"
                        : "1px solid #ced4da",
                    }}
                  >
                    <option value="">{t("Select Status")}</option>
                    <option value="Yes">{t("Yes")}</option>
                    <option value="No">{t("No")}</option>
                  </select>
                </Col>
              </Row>

              {/* Show fields based on Red Crescent selection */}
              {isRedCrescent === "No" && (
                <Row className="mb-4">
                  <Col md={2}>
                    <strong>{t("Reason for Stop")}</strong>
                  </Col>
                  <Col md={4}>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        incomes?.redCrescent?.additional_info?.reason || ""
                      }
                      onChange={(e) =>
                        setIncomes({
                          ...incomes,
                          redCrescent: {
                            ...incomes.redCrescent,
                            additional_info: {
                              reason: e.target.value,
                            },
                          },
                        })
                      }
                      required
                      style={{
                        height: "40px",
                        border: errorFields.includes("reasonForStop")
                          ? "1px solid red"
                          : "1px solid #ced4da",
                      }}
                    />
                  </Col>
                </Row>
              )}
              {isRedCrescent === "Yes" && (
                <Row className="mb-4">
                  <Col md={2}>
                    <strong>{t("Amount Received")}</strong>
                  </Col>
                  <Col md={4}>
                    <input
                      type="number"
                      className="form-control"
                      value={
                        incomes.redCrescent?.amount
                          ? parseInt(incomes.redCrescent.amount, 10)
                          : ""
                      }
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^\d]/g, "");
                        setIncomes({
                          ...incomes,
                          redCrescent: {
                            ...incomes.redCrescent,
                            amount: value,
                          },
                        });
                      }}
                      required
                      style={{
                        height: "40px",
                        border: errorFields.includes("redCrescentAmount")
                          ? "1px solid red"
                          : "1px solid #ced4da",
                      }}
                    />
                  </Col>
                </Row>
              )}

              {/* Bills Section */}
              <Row className="mb-4">
                <Col md={2}>
                  <strong>{t("Bills Section")}</strong>
                </Col>
                <Col md={4}>
                  <Accordion defaultActiveKey={null}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>{t("Bills Section")}</Accordion.Header>
                      <Accordion.Body>
                        {/* Bill Notes */}
                        <small
                          className="text-center d-block mb-3"
                          style={{ color: "red" }}
                        >
                          {t("If there's no bill, put 0")}
                        </small>

                        <small
                          className="text-center d-block mb-3"
                          style={{ color: "green" }}
                        >
                          {t(
                            "The total amount will be calculated automatically after entering all bills."
                          )}
                        </small>

                        {/* Water Bill */}
                        <Row className="mb-3">
                          <Col md={6}>
                            <label>{t("Water Bill")}</label>
                            <input
                              type="number"
                              className="form-control"
                              value={expenses.waterBill?.amount || ""}
                              onChange={(e) => {
                                setExpenses({
                                  ...expenses,
                                  waterBill: {
                                    ...expenses.waterBill,
                                    amount: e.target.value,
                                  },
                                });
                                calculateTotalExpenses(
                                  "waterBill",
                                  e.target.value
                                );
                              }}
                              required
                              style={{
                                height: "40px",
                                border: errorFields.includes("waterBill")
                                  ? "1px solid red"
                                  : "1px solid #ced4da",
                              }}
                            />
                          </Col>

                          {/* Gas Bill */}
                          <Col md={6}>
                            <label>{t("Gas Bill")}</label>
                            <input
                              type="number"
                              className="form-control"
                              value={expenses.gasBill?.amount || ""}
                              onChange={(e) => {
                                setExpenses({
                                  ...expenses,
                                  gasBill: {
                                    ...expenses.gasBill,
                                    amount: e.target.value,
                                  },
                                });
                                calculateTotalExpenses(
                                  "gasBill",
                                  e.target.value
                                );
                              }}
                              required
                              style={{
                                height: "40px",
                                border: errorFields.includes("gasBill")
                                  ? "1px solid red"
                                  : "1px solid #ced4da",
                              }}
                            />
                          </Col>
                        </Row>

                        {/* Electricity Bill */}
                        <Row className="mb-3">
                          <Col md={6}>
                            <label>{t("Electricity Bill")}</label>
                            <input
                              type="number"
                              className="form-control"
                              value={expenses.electricityBill?.amount || ""}
                              onChange={(e) => {
                                setExpenses({
                                  ...expenses,
                                  electricityBill: {
                                    ...expenses.electricityBill,
                                    amount: e.target.value,
                                  },
                                });
                                calculateTotalExpenses(
                                  "electricityBill",
                                  e.target.value
                                );
                              }}
                              required
                              style={{
                                height: "40px",
                                border: errorFields.includes("electricityBill")
                                  ? "1px solid red"
                                  : "1px solid #ced4da",
                              }}
                            />
                          </Col>

                          {/* Internet Bill */}
                          <Col md={6}>
                            <label>{t("Internet Bill")}</label>
                            <input
                              type="number"
                              className="form-control"
                              value={expenses.internetBill?.amount || ""}
                              onChange={(e) => {
                                setExpenses({
                                  ...expenses,
                                  internetBill: {
                                    ...expenses.internetBill,
                                    amount: e.target.value,
                                  },
                                });
                                calculateTotalExpenses(
                                  "internetBill",
                                  e.target.value
                                );
                              }}
                              required
                              style={{
                                height: "40px",
                                border: errorFields.includes("internetBill")
                                  ? "1px solid red"
                                  : "1px solid #ced4da",
                              }}
                            />
                          </Col>
                        </Row>

                        {/* Other Bill and Total of Bills */}
                        <Row className="mb-3">
                          <Col md={6}>
                            <label>{t("Other Bill")}</label>
                            <input
                              type="number"
                              className="form-control"
                              value={expenses.otherBill?.amount || 0}
                              onChange={(e) => {
                                setExpenses({
                                  ...expenses,
                                  otherBill: {
                                    ...expenses.otherBill,
                                    amount: e.target.value,
                                  },
                                });
                                calculateTotalExpenses(
                                  "otherBill",
                                  e.target.value
                                );
                              }}
                              required
                              style={{
                                height: "40px",
                                border: errorFields.includes("otherBill")
                                  ? "1px solid red"
                                  : "1px solid #ced4da",
                              }}
                            />
                          </Col>

                          <Col md={6}>
                            <label>{t("Total of Bills")}</label>
                            <input
                              type="number"
                              className="form-control"
                              value={totalExpenses}
                              readOnly
                              style={{ height: "40px" }}
                            />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>

              {/* Show total bills outside the Accordion when it's calculated */}
              {totalExpenses > 0 && (
                <Row className="mb-4">
                  <Col md={2}>
                    <strong>{t("Total of Bills")}</strong>
                  </Col>
                  <Col md={4}>
                    <input
                      type="number"
                      className="form-control"
                      value={totalExpenses}
                      readOnly
                      style={{ height: "40px" }}
                    />
                  </Col>
                </Row>
              )}

              {/* Action Buttons */}
              <div className="d-flex justify-content-start align-items-center gap-2 mt-3">
                <Button
                  color="primary"
                  onClick={saveFamilyInfo}
                  disabled={isSaving}
                >
                  {isSaving ? t("Saving...") : t("Save")}
                </Button>
                <Button color="secondary" onClick={() => navigate(-1)}>
                  {t("Cancel")}
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(FamilyInformation);

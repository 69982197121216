import React, { useEffect, useState } from "react";
import instance from "base_url";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button, Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import { MEDIA_URL } from "../../configs";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import logoSm from "../../assets/images/logo-sm.png";

const FamilyReport = () => {
  const { id, familyId } = useParams();
  const { t, i18n } = useTranslation();
  const [individuals, setIndividuals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [family, setFamily] = useState({});
  const [headOfFamily, setHeadOfFamily] = useState(null);
  const [rentAmount, setRentAmount] = useState(0);
  const [isRedCrescent, setIsRedCrescent] = useState(null);
  const [redCrescent, setRedCrescent] = useState(null);
  const [totalBills, setTotalBills] = useState(0);
  const [income, setIncome] = useState(0);
  const [volunteerName, setVolunteerName] = useState("0");
  const [requestDate] = useState(new Date().toLocaleDateString());

  // Counters for condition and disability severities
  const [illnessSeverity, setIllnessSeverity] = useState({ 1: 0, 2: 0, 3: 0 });
  const [disabilitySeverity, setDisabilitySeverity] = useState({
    1: 0,
    2: 0,
    3: 0,
  });

  // Variables to store age counts
  const [underTwo, setUnderTwo] = useState(0);
  const [overTwo, setOverTwo] = useState(0);
  const [seniors, setSeniors] = useState(0);

  // Fetch family and individual data
  const fetchFamilyIndividuals = async () => {
    try {
      const { data } = await instance.get(`/families/${familyId}/`);
      setIndividuals(data.individuals || []);
      setFamily(data);

      const head = data.individuals.find((ind) => ind.is_head_of_family);
      setHeadOfFamily(head);

      const redCrescent = data.incomes?.find(
        (item) => item.title?.item_name === "Red Crescent"
      );

      setRedCrescent(redCrescent);
      setIsRedCrescent(redCrescent && redCrescent.amount !== 0 ? "Yes" : "No");

      const income = data.incomes?.find(
        (item) => item.title?.item_name === "Salary"
      );
      setIncome(income?.amount || 0);

      const totalExpenses = [
        "Water Bill",
        "Gas Bill",
        "Electricity Bill",
        "Internet Bill",
        "Other",
      ].reduce(
        (sum, billType) =>
          sum +
          parseInt(
            data.expenses?.find((item) => item.title?.item_name === billType)
              ?.amount || 0
          ),
        0
      );
      setTotalBills(totalExpenses);

      const rent = data.expenses?.find(
        (item) => item.title?.item_name === "Rent Amount"
      );
      setRentAmount(rent?.amount || 0);

      // Calculate age categories
      calculateAgeCategories(data.individuals);
      // Calculate condition and disability severities
      calculateConditionSeverities(data.individuals);
    } catch (error) {
      console.error("Error fetching family data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to calculate age categories based on individual ages
  const calculateAgeCategories = (individuals) => {
    const currentYear = new Date().getFullYear();
    let underTwoCount = 0;
    let overTwoCount = 0;
    let seniorsCount = 0;

    individuals.forEach((individual) => {
      const birthYear = new Date(individual.date_of_birth).getFullYear();
      const age = currentYear - birthYear;

      if (age < 2) {
        underTwoCount++;
      } else if (age >= 2 && age < 55) {
        overTwoCount++;
      } else if (age >= 55) {
        seniorsCount++;
      }
    });
    setUnderTwo(underTwoCount);
    setOverTwo(overTwoCount);
    setSeniors(seniorsCount);
  };

  // Function to calculate age based on date of birth
  const calculateAge = (dateOfBirth) => {
    const birthDate = new Date(dateOfBirth);
    const ageDiff = new Date() - birthDate;
    const age = new Date(ageDiff).getUTCFullYear() - 1970;
    return age;
  };

  // Function to calculate the number of conditions by severity
  const calculateConditionSeverities = (individuals) => {
    const illnessCount = { 1: 0, 2: 0, 3: 0 };
    const disabilityCount = { 1: 0, 2: 0, 3: 0 };

    individuals.forEach((individual) => {
      if (individual.has_condition) {
        if (individual.condition_type === "illness") {
          illnessCount[individual.condition_severity]++;
        } else if (individual.condition_type === "disability") {
          disabilityCount[individual.condition_severity]++;
        }
      }
    });

    setIllnessSeverity(illnessCount);
    setDisabilitySeverity(disabilityCount);
  };

  useEffect(() => {
    fetchFamilyIndividuals();
  }, [familyId]);

  // Function to download PDF
  const downloadPDF = async () => {
    const input = document.getElementById("pdf-content");
    const buttons = document.querySelectorAll(".d-print-none");

    buttons.forEach((button) => (button.style.display = "none"));

    const images = input.querySelectorAll("img");
    await Promise.all(
      Array.from(images).map((img) =>
        img.complete
          ? Promise.resolve()
          : new Promise((resolve) => {
              img.onload = resolve;
              img.onerror = resolve;
            })
      )
    );

    const canvas = await html2canvas(input, { useCORS: true });
    const imgData = canvas.toDataURL("image/png");

    const newPdf = await PDFDocument.create();
    const pdfWidth = 595.28;
    const pdfHeight = 841.89;

    const firstPage = newPdf.addPage([pdfWidth, pdfHeight]);
    const pngImage = await newPdf.embedPng(imgData);
    firstPage.drawImage(pngImage, {
      x: 0,
      y: 0,
      width: pdfWidth,
      height: pdfHeight,
    });

    const mergedPdfBytes = await newPdf.save();
    const headOfFamilyName = headOfFamily
      ? `${headOfFamily.first_name}_${headOfFamily.last_name}`
      : "Unknown";
    const fileName = `${t("Family Report")}_${headOfFamilyName}.pdf`;

    const finalPdfBlob = new Blob([mergedPdfBytes], {
      type: "application/pdf",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(finalPdfBlob);
    link.download = fileName;
    link.click();

    buttons.forEach((button) => (button.style.display = "block"));
  };

  const isRTL = i18n.language === "ar";

  console.log("individuals", individuals);
  console.log("volunteer_name", family);

  return (
    <React.Fragment>
      <div className="page-content" dir={isRTL ? "rtl" : "ltr"}>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center mb-3">
              <Col md={5}>
                <h6 className="page-title">{t("Family Report")}</h6>
              </Col>
            </Row>
          </div>
          <Row>
            <div className="col-15">
              <Card>
                <CardBody id="pdf-content">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "2px",
                    }}
                  >
                    <div style={{ fontSize: "14px" }}>
                      <h4 style={{ margin: 0, fontSize: "16px" }}>
                        <strong>
                          {t("Family Id")}: {familyId}
                        </strong>
                      </h4>
                      <p style={{ margin: 0, fontSize: "14px", color: "#555" }}>
                        {t("Request Date")}: {new Date().toLocaleDateString()}
                      </p>
                    </div>

                    <h3 style={{ marginTop: 0, fontSize: "18px" }}>
                      <img src={logoSm} alt="logo" height="30" />
                    </h3>
                  </div>
                  <hr style={{ marginTop: "1px" }} />
                  <Row>
                    <Col md={6} style={{ marginBottom: "2px" }}>
                      <Card>
                        <CardBody>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              marginBottom: "2px",
                              color: "#333",
                              textTransform: "uppercase",
                            }}
                          >
                            {t("Request Information")}
                          </div>
                          <Table bordered responsive>
                            <tbody>
                              <tr>
                                <td style={{ fontWeight: "bold" }}>
                                  {t("Rent Amount")}:
                                </td>
                                <td>
                                  {rentAmount ? Math.floor(rentAmount) : 0}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: "bold" }}>
                                  {t("Red Crescent")}:
                                </td>
                                <td>
                                  {isRedCrescent === "Yes" ? (
                                    <>
                                      {t("Yes")} : &nbsp;
                                      <span style={{ fontWeight: "bold" }}>
                                        {redCrescent?.amount ? (
                                          Math.floor(redCrescent.amount)
                                        ) : (
                                          <i className="fas fa-ban"></i>
                                        )}
                                      </span>
                                    </>
                                  ) : isRedCrescent === "No" ? (
                                    <>
                                      {t("No")} : &nbsp;
                                      <span style={{ fontWeight: "bold" }}>
                                        {redCrescent?.additional_info
                                          ?.reason || (
                                          <i className="fas fa-ban"></i>
                                        )}
                                      </span>
                                    </>
                                  ) : (
                                    <i className="fas fa-ban"></i>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: "bold" }}>
                                  {t("Family Income")}:
                                </td>
                                <td>{income ? Math.floor(income) : 0}</td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: "bold" }}>
                                  {t("Total of Bills")}:
                                </td>
                                <td>{totalBills || 0}</td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: "bold" }}>
                                  {t("Mobile Number")}:
                                </td>
                                <td>
                                  {headOfFamily?.mobile_number ||
                                    (individuals.length > 0
                                      ? individuals[0].mobile_number
                                      : 0)}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: "bold" }}>
                                  {t("Address")}:
                                </td>
                                <td style={{ wordBreak: "break-word" }}>
                                  {headOfFamily?.address ||
                                    (individuals.length > 0
                                      ? individuals[0].address
                                      : "0")}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <div
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              marginBottom: "2px",
                              color: "#333",
                              textTransform: "uppercase",
                            }}
                          >
                            {t("Age Categories")}
                          </div>
                          <Table bordered responsive>
                            <thead>
                              <tr>
                                <th>{t("Category")}</th>
                                <th style={{ textAlign: "center" }}>
                                  {t("Count")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{t("Children under 2 years")}</td>
                                <td style={{ textAlign: "center" }}>
                                  {underTwo}
                                </td>
                              </tr>
                              <tr>
                                <td>{t("Children over 2 years")}</td>
                                <td style={{ textAlign: "center" }}>
                                  {overTwo}
                                </td>
                              </tr>
                              <tr>
                                <td>{t("Seniors (55 years and above)")}</td>
                                <td style={{ textAlign: "center" }}>
                                  {seniors}
                                </td>
                              </tr>
                              <tr>
                                <td>{t("Total family members")}</td>
                                <td style={{ textAlign: "center" }}>
                                  {individuals.length}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* Request Details Section */}
                    <Col md={6}>
                      <Card style={{ marginBottom: "2px" }}>
                        <CardBody>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              marginBottom: "2px",
                              color: "#333",
                              textTransform: "uppercase",
                            }}
                          >
                            {t("Request Details")}
                          </div>
                          <Table bordered responsive>
                            <tbody>
                              <tr>
                                <td style={{ fontWeight: "bold" }}>
                                  {t("Applicant")}:
                                </td>
                                <td>
                                  {headOfFamily ? (
                                    `${headOfFamily.first_name} ${headOfFamily.last_name}`
                                  ) : (
                                    <i className="fas fa-ban"></i>
                                  )}
                                </td>
                              </tr>
                              {/* <tr>
                                <td style={{ fontWeight: "bold" }}>
                                  {t("Father's Name")}:
                                </td>
                                <td>
                                  {headOfFamily
                                    ? headOfFamily.father_name || "0"
                                    : individuals.length > 0
                                    ? individuals.reduce(
                                        (oldest, individual) => {
                                          return new Date(
                                            individual.date_of_birth
                                          ) < new Date(oldest.date_of_birth)
                                            ? individual
                                            : oldest;
                                        }
                                      ).father_name || "0"
                                    : "0"}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: "bold" }}>
                                  {t("Mother's Name")}:
                                </td>
                                <td>
                                  {headOfFamily ? (
                                    headOfFamily.mother_name || "0"
                                  ) : (
                                    <i className="fas fa-ban"></i>
                                  )}
                                </td>
                              </tr> */}
                              <tr>
                                <td style={{ fontWeight: "bold" }}>
                                  {t("Volunteer Name")}:
                                </td>
                                <td>
                                  {individuals.length > 0 ? (
                                    individuals[0].volunteer_name
                                  ) : (
                                    <i className="fas fa-ban"></i>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: "bold" }}>
                                  {t("Request ID")}:
                                </td>
                                <td>
                                  {individuals.length > 0 ? (
                                    individuals[0].last_updated_by_visit
                                  ) : (
                                    <i className="fas fa-ban"></i>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: "bold" }}>
                                  {t("Request Date")}:
                                </td>
                                <td>
                                  {individuals.length > 0 ? (
                                    new Date(
                                      individuals[0].visit_date
                                    ).toLocaleDateString("en-CA")
                                  ) : (
                                    <i className="fas fa-ban"></i>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              marginBottom: "2px",
                              color: "#333",
                              textTransform: "uppercase",
                            }}
                          >
                            {t("Medical Condition / Disability")}
                          </div>
                          <Table bordered responsive>
                            <thead>
                              <tr>
                                <th>{t("Category")}</th>
                                <th>{t("Severity 1")}</th>
                                <th>{t("Severity 2")}</th>
                                <th>{t("Severity 3")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{t("Illness Cases")}</td>
                                <td>{illnessSeverity[1]}</td>
                                <td>{illnessSeverity[2]}</td>
                                <td>{illnessSeverity[3]}</td>
                              </tr>
                              <tr>
                                <td>{t("Disability Cases")}</td>
                                <td>{disabilitySeverity[1]}</td>
                                <td>{disabilitySeverity[2]}</td>
                                <td>{disabilitySeverity[3]}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  {/* Family Tree Section */}
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                          <div
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              marginBottom: "2px",
                              color: "#333",
                              textTransform: "uppercase",
                            }}
                          >
                            {t("Family Tree")}
                          </div>
                          <div
                            style={{
                              border: "1px solid #ccc",
                              padding: "10px",
                              borderRadius: "5px",
                              backgroundColor: "#f9f9f9",
                              marginBottom: "2px",
                            }}
                          >
                            <img
                              src={`${MEDIA_URL}genogram/${family.id}.png`}
                              alt="Family Tree"
                              style={{ width: "50%", height: "auto" }}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  {/* Individual information table */}
                  <Row>
                    <div className="col-12">
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          marginBottom: "2px",
                          color: "#333",
                          textTransform: "uppercase",
                        }}
                      >
                        <h4>{t("Individuals Information")}</h4>
                      </div>
                      <Table bordered>
                        <thead>
                          <tr>
                            <th>{t("Name")}</th>
                            <th>{t("Date of Birth (Age)")}</th>
                            <th>{t("Job Title")}</th>
                            <th>{t("Salary")}</th>
                            <th>{t("Status")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {individuals.length > 0 ? (
                            <>
                              {headOfFamily && (
                                <tr key={headOfFamily.id}>
                                  <td>
                                    <strong>{`${headOfFamily.first_name} ${
                                      headOfFamily.last_name
                                    } (${t("Head of Family")})`}</strong>
                                  </td>
                                  <td>
                                    {headOfFamily.date_of_birth ? (
                                      `${
                                        headOfFamily.date_of_birth
                                      } (${calculateAge(
                                        headOfFamily.date_of_birth
                                      )})`
                                    ) : (
                                      <i
                                        className="fas fa-ban"
                                        style={{ color: "red" }}
                                      ></i>
                                    )}
                                  </td>
                                  <td>
                                    {headOfFamily.job_title ? (
                                      headOfFamily.job_title
                                    ) : (
                                      <i
                                        className="fas fa-ban"
                                        style={{ color: "red" }}
                                      ></i>
                                    )}
                                  </td>
                                  <td>
                                    {headOfFamily.is_working &&
                                    headOfFamily.salary ? (
                                      parseInt(headOfFamily.salary, 10)
                                    ) : (
                                      <i
                                        className="fas fa-ban"
                                        style={{ color: "red" }}
                                      ></i>
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      color:
                                        headOfFamily.status === "alive"
                                          ? "green"
                                          : headOfFamily.status === "dead"
                                          ? "red"
                                          : headOfFamily.status === "lost"
                                          ? "gray"
                                          : "black",
                                    }}
                                  >
                                    {t(headOfFamily.status)}
                                  </td>
                                </tr>
                                
                              )}

                              {individuals
                                .filter(
                                  (individual) => !individual.is_head_of_family
                                )
                                .map((individual) => (
                                  <tr key={individual.id}>
                                    <td>
                                      {`${individual.first_name} ${
                                        individual.last_name
                                      } ${
                                        individual.is_head_of_family
                                          ? `(${t("Head of Family")})`
                                          : ""
                                      }`}
                                      {individual.id ===
                                        headOfFamily?.partner_id && (
                                        <strong> ({t("Wife's")})</strong>
                                      )}
                                    </td>
                                    <td>
                                      {individual.date_of_birth ? (
                                        `${
                                          individual.date_of_birth
                                        } (${calculateAge(
                                          individual.date_of_birth
                                        )})`
                                      ) : (
                                        <i
                                          className="fas fa-ban"
                                          style={{ color: "red" }}
                                        ></i>
                                      )}
                                    </td>
                                    <td>
                                      {individual.job_title ? (
                                        individual.job_title
                                      ) : (
                                        <i
                                          className="fas fa-ban"
                                          style={{ color: "red" }}
                                        ></i>
                                      )}
                                    </td>
                                    <td>
                                      {individual.is_working &&
                                      individual.salary ? (
                                        parseInt(individual.salary, 10)
                                      ) : (
                                        <i
                                          className="fas fa-ban"
                                          style={{ color: "red" }}
                                        ></i>
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        color:
                                          individual.status === "alive"
                                            ? "green"
                                            : individual.status === "dead"
                                            ? "red"
                                            : individual.status === "lost"
                                            ? "gray"
                                            : "black",
                                      }}
                                    >
                                      {t(individual.status)}
                                    </td>
                                  </tr>
                                ))}
                            </>
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">
                                <i
                                  className="fas fa-ban"
                                  style={{ color: "red" }}
                                ></i>{" "}
                                {t("No Data Available")}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Row>

                  <div
                    style={{
                      borderTop: "1px solid #ddd",
                    }}
                  ></div>

                  <Row
                    className="justify-content-between"
                    style={{ marginTop: "2px", marginBottom: "70px" }}
                  >
                    <Col md={5} className="text-center">
                      <p
                        style={{
                          marginBottom: "10px",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {t("Volunteer Name")}
                      </p>
                      <div
                        style={{ borderTop: "1px solid #ccc", width: "100%" }}
                      />
                    </Col>
                    <Col md={5} className="text-center">
                      <p
                        style={{
                          marginBottom: "10px",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {t("Applicant and Signature")}
                      </p>
                      <div
                        style={{ borderTop: "1px solid #ccc", width: "100%" }}
                      />
                    </Col>
                  </Row>

                  {/* Uncomment this part if you want to enable the Emergency modal */}
                  {/* <Emergency
                    isOpen={isModalOpen}
                    toggle={() => setIsModalOpen(false)}
                    setDescription={(desc) => {
                      setDescription(desc);
                    }}
                  /> */}
                </CardBody>
              </Card>

              <div className="d-print-none">
                <div className="d-flex justify-content-center">
                  <Button color="success" onClick={() => downloadPDF()}>
                    <i className="fa fa-file-pdf"></i> {t("Download PDF")}
                  </Button>{" "}
                  {/* <Button color="primary" onClick={() => setIsModalOpen(true)}>
                      {t("Send")} </Button> */}
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FamilyReport;

import React, { useEffect, useState, useContext } from "react";

// Components
import { Container, Row, Col, Button } from "reactstrap";
import instance from "base_url";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function CreateSupportType() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation()
  const [supportType, setSupportType] = useState([]);

  // Get Support Type Details
  const getSupportTypeDetais = async () => {
    
    try {
      const { data } = await instance.get(`/support-type/${state}/`);
      setSupportType(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if(state){
      getSupportTypeDetais();
    }
  }, []);

  const updateServerParams = (value, param) => {
    const newSupportType = { ...supportType };
    newSupportType[param] = value;
    setSupportType(newSupportType);
    console.log(newSupportType)
  };

  const saveSupport = async () => {
    try {
      const res = await state ? instance.put(`/support-type/${state}/`, supportType) : instance.post('/support-type/', supportType)
      navigate('/support-types');
    } catch (e){ 
      console.error(e)
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-title-box">
          <Row className="align-items-center mb-3">
            <Col md={8}>
              <h6 className="page-title">{t("Support Type Detail Page")}</h6>
            </Col>
          </Row>

          <div className="form-page-container">
              {/* Support Type Name */}
              <div className='row mb-4'>
                <div className='col-2 align-content-center'>
                  <p className='m-0'><strong>{t("Support Type Name")}</strong></p>
                </div>
                <div className='col-10'>
                <input
                    className="form-control"
                    placeholder={supportType ? supportType.name : t('Support type name')} 
                    onChange={(value)=>{updateServerParams(value.target.value, 'name')}}
                  />
                </div>
              </div>

              {/* Support Type Description */}
              <div className='row mb-4'>
                <div className='col-2 align-content-center'>
                  <p className='m-0'><strong>{t("Support Type Description")}</strong></p>
                </div>
                <div className='col-10'>
                <textarea
                    maxlength="225" rows="5"
                    className="form-control"
                    placeholder={supportType ? supportType.description : t('Support type description')}
                    onChange={(value)=>{updateServerParams(value.target.value, 'description')}}
                  />
                </div>
              </div>
            
            {/* Actions */}
            <Button onClick={()=>{saveSupport()}}>
            {t("Save")} 
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}